.fantasy .mean>.achievements {
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    height: 100%;
    overflow-y: scroll;

    &>.glass:not(.non-ideal) {
      min-height: 70px;
      max-height: 70px;
      display: flex;
      box-sizing: border-box;
      padding: 0 10px;
      align-items: center;
      grid-gap: 20px;
      position: relative;

      &>img {
        width: 42px;
        height: 42px;
      }

      .lvl-info {
        height: 16px;
        width: 60px;
        transform: skewX(-10deg);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 4px;
        top: -5px;
        background: var(--secondary);
        color: var(--secondary-text);
        white-space: nowrap;
        font-size: 8px;
        text-transform: uppercase;
      }

      &>.description {
        padding: 5px 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        color: var(--glass-primary-color);
        justify-content: space-between;
        align-content: space-between;

        .estimated {
          display: flex;
          flex-direction: row;
          grid-gap: 10px;
          height: 45%;
          align-items: center;

          .estimated-indicator {
            font-size: 11px;
            width: 25%;
            white-space: nowrap;
          }

          .estimated-bonus {
            display: flex;
            align-items: center;
            grid-gap: 4px;
            font-size: 11px;
            width: 23%;
            justify-content: end;
            transform: skewX(-10deg);

            &>img {
              height: 11px;
              width: 11px;
            }
          }

          .estimated-bar {
            background: rgba(0, 0, 0, .25);
            height: 16px;
            width: calc(100% - 16px);
            border-radius: 8px;
            position: relative;

            .estimated-value {
              height: 16px;
              position: absolute;
              border-radius: 8px;
              left: 0;
              background: var(--accent);
              opacity: .9;
            }

            .estimated-title {
              display: flex;
              justify-content: center;
              align-items: center;
              white-space: nowrap;
              font-weight: 500;
              font-size: 9px;
              position: absolute;
              top: 3px;
              left: 25%;
              width: 50%;
            }
          }
        }

        &::before {
          content: '';
          position: absolute;
          left: 5px;
          right: 5px;
          height: 1px;
          background: linear-gradient(to right, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.03));
          top: 50%;
        }

        &>span {
            width: 77%;
            height: 45%;
            font-size: 12px;
            font-weight: 500;
            max-width: 100%;
            display: flex;
            align-items: end;
            margin-right: 4px;
            text-align: left;
            color: var(--glass-primary-color);
          }
      }

      &>.factor {
        display: flex;
        flex-direction: column;
        width: 90px;
        align-items: center;

        &>span {
          font-size: 10px;
          font-weight: 300;
          color: var(--glass-primary-color);
        }

        b {
          font-weight: 500;
          font-size: 11px;
          margin-bottom: 4px;
          font-style: italic;
          color: var(--glass-highlight-color);
        }

        .smooth {
          background: #fff;
          margin-bottom: 4px;
          background: var(--darken);
          color: var(--darken-inner-text);
          width: 38px;
          text-align: center;
          font-size: 13px;
          font-weight: 500;
          height: 20px;
          box-sizing: border-box;
          padding-top: 3px;

          &::before {
            border-radius: 5px;
          }
        }
      }

      &>.outcome {
        width: calc(100% - 278px);
        height: 36px;
        padding-left: 12px;
        box-sizing: border-box;

        .glass {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          padding-right: 4px;
          font-weight: 500;
          font-size: 14px;
          color: var(--glass-primary-color);
          color: #fff;

          b {
            position: absolute;
            top: calc(50% - 8px);
            left: -7px;
            display: inline-block;
            box-sizing: border-box;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            background: var(--glass-backdrop);
            display: flex;
            align-items: center;
            justify-content: center;

            &.won {
              background: var(--positive-bgd);
            }

            &.lost {
              background: var(--negative-bgd);
            }

            i {
              font-size: 10px;
            }
          }

          &::before {
            width: 16px;
            height: 16px;
            background-image: url('../assets/coin_small.png');
            background-size: 100%;
            content: '';
            display: block;
            position: absolute;
            top: calc(50% - 8px);
            right: -7px;
          }
        }
      }
    }
}
