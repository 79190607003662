.fantasy .ratings {
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .types {
        display: flex;
        justify-content: space-between;
        padding: 0px 10px 8px;

        .smooth {
            background: var(--darken);
            color: var(--darken-inner-text);
            font-size: 13px;
            padding: 6px 9px;
            transition: all 0.45s ease-in-out;
            font-weight: 500;

            &.active {
                background: var(--accent);
                color: var(--accent-text);
            }
        }
    }

    .list {
        height: calc(100% - 35px);
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow-y: scroll;
        flex-flow: wrap;
        justify-content: center;

        .points {
            letter-spacing: 4px;
            font-weight: bolder;
            font-size: 13px;
            color: var(--glass-primary-color);
        }

        .glass {
            height: 34px;
            display: flex;
            align-items: center;
            font-size: 13px;
            width: 100%;

            &.user {
              background: var(--accent);

              .pos {
                color: var(--accent-text);
              }

              .value {
                color: var(--accent-text);
              }

              .player {
                color: var(--accent-text);
              }
            }

            &>* {
                box-sizing: border-box;
            }

            .pos {
                width: 38px;
                padding-right: 10px;
                text-align: right;
                color: var(--glass-secondary-color);
            }

            .value {
                width: 80px;
                padding-right: 10px;
                text-align: right;
                color: var(--glass-primary-color);
                font-weight: 500;
            }

            .player {
                width: calc(100% - 118px);
                color: var(--glass-highlight-color);
                text-transform: uppercase;
            }
        }
    }
}
