.mean .loader {
    top: 10px;
    left: 10px;
    bottom: 10px;
    right: 10px;
    height: unset !important;
}

.fantasy .mean>.history {
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
    overflow-y: scroll;

    &>.glass:not(.non-ideal) {
        height: 60px;
        display: flex;
        box-sizing: border-box;
        padding: 0 10px;
        align-items: center;

        &>.date {
            width: 38px;
            font-size: 12px;
            color: var(--glass-highlight-color);
            opacity: .9;
        }

        &>.teams {
            width: 154px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            position: relative;
            color: var(--glass-primary-color);

            &::before {
                content: '';
                position: absolute;
                left: 5px;
                right: 5px;
                height: 1px;
                background: linear-gradient(to right, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.03));
                top: 50%;
            }

            &>div {
                font-size: 13px;
                font-weight: 500;
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &>span {
                    width: 20px;
                    margin-right: 4px;
                    text-align: center;
                    display: inline-block;
                    color: var(--glass-primary-color);
                }
            }
        }

        &>.factor {
            display: flex;
            flex-direction: column;
            width: 90px;
            align-items: center;

            &>span {
                font-size: 10px;
                font-weight: 300;
                color: var(--glass-primary-color);
            }

            b {
                font-weight: 500;
                font-size: 11px;
                margin-bottom: 4px;
                font-style: italic;
                color: var(--glass-highlight-color);
            }

            .smooth {
                background: #fff;
                margin-bottom: 4px;
                background: var(--darken);
                color: var(--darken-inner-text);
                width: 38px;
                text-align: center;
                font-size: 13px;
                font-weight: 500;
                height: 20px;
                box-sizing: border-box;
                padding-top: 3px;

                &::before {
                    border-radius: 5px;
                }
            }
        }

        &>.outcome {
            width: calc(100% - 278px);
            height: 36px;
            padding-left: 12px;
            box-sizing: border-box;

            .glass {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                padding-right: 4px;
                font-weight: 500;
                font-size: 14px;
                color: var(--glass-primary-color);
                color: #fff;

                b {
                    position: absolute;
                    top: calc(50% - 8px);
                    left: -7px;
                    display: inline-block;
                    box-sizing: border-box;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    background: var(--glass-backdrop);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.won {
                        background: var(--positive-bgd);
                    }

                    &.lost {
                        background: var(--negative-bgd);
                    }

                    i {
                        font-size: 10px;
                    }
                }

                &::before {
                    width: 16px;
                    height: 16px;
                    background-image: url('../assets/coin_small.png');
                    background-size: 100%;
                    content: '';
                    display: block;
                    position: absolute;
                    top: calc(50% - 8px);
                    right: -7px;
                }
            }
        }
    }
}
