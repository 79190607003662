@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

// :root {
//     --main-grd: linear-gradient(to bottom, var(--blue-500), var(--blue-700));
//     --darken: var(--blue-700);
//     --darken-inner-text: #fff;
//     --lighten: var(--blue-400);
//     --accent: linear-gradient(to bottom, var(--yellow-400), var(--yellow-500));
//     --accent-text: var(--gray-900);
//     --positive-bgd: var(--green-600);
//     --neutral-bgd: var(--yellow-400);
//     --negative-bgd: var(--red-400);
//     --glass-primary-color: #d6e2f5;
//     --glass-secondary-color: #0b3678;
//     --glass-highlight-color: #ffe7c8;
//     --glass-backdrop: #5483cf;
// }

:root {
    --main-grd: linear-gradient(-45deg, #743bbe 10%, #222 35%, #222 75%, #743bbe);
    --darken: var(--gray-800);
    --darken-inner-text: #fff;
    --lighten: var(--gray-700);
    --lighten-inner-text: #96fff5;
    --accent: linear-gradient(to bottom, #02c6b3, #09a899);
    --accent-text: var(--gray-900);
    --secondary: linear-gradient(to bottom, #55347e, #7a4db1);
    --secondary-text: #f9f0d7;
    --positive-bgd: var(--green-600);
    --neutral-bgd: var(--yellow-400);
    --negative-bgd: var(--red-400);
    --glass-primary-color: #defffc;
    --glass-secondary-color: #fff;
    --glass-highlight-color: #fff;
    --glass-aggressive-color: #ffc34d;
    --glass-backdrop: #5483cf;
}

body {
    margin: 0;
    font-family: 'Rubik', sans-serif;
}

.fantasy {
    background: var(--main-grd);
    height: 100vh;
    position: relative;

    .glass {
        height: 100%;
        background: rgba(255, 255, 255, .11);
        backdrop-filter: blur(6px);
        border-radius: 5px;
        box-shadow: 1px 1px 6px rgba(0, 0, 0, .11);
    }

    .non-ideal {
        margin: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        .pi {
            color: var(--glass-highlight-color);
            font-size: 24px;
            margin-bottom: 24px;
        }

        .title {
            font-size: 14px;
            color: var(--glass-primary-color);
            padding: 0 20px;
            text-align: center;
        }

        .p-button {
            margin-top: 30px;
            background: var(--accent);
            border: none;
            padding: .7rem 1.25rem;
            font-family: 'Rubik', sans-serif;
            position: absolute;
            left: 10px;
            right: 10px;
            bottom: 10px;

            span {
                color: var(--accent-text);
                font-weight: 500;
                font-size: 14px;
            }
        }
    }

    .smooth {
        position: relative;
        border-radius: 10px;

        &::before {
            content: '';
            left: -6px;
            right: -6px;
            position: absolute;
            top: 0;
            bottom: 0;
            background: inherit;
            transform: skewX(-10deg);
            border-radius: 10px;
        }

        &>* {
            position: relative;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('./assets/rect_light.png');
        background-size: cover;
        background-position: bottom center;
    }

    &>div.screen {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: calc(100vh - 56px);
        box-sizing: border-box;
        padding-top: 24px;

        .mean {
            height: calc(100% - 58px);
            display: flex;
            flex-direction: column;
            position: relative;

            &>* {
                height: 100%;
            }
        }
    }
}
