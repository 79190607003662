.init-dialog {
    box-sizing: border-box;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 50px;

    .title, .brand {
        font-weight: 500;
        color: #fff;
        text-transform: uppercase;
    }

    .description {
        text-align: center;
        margin: 20px 0;
        font-weight: 300;
        font-size: 13px;
        color: var(--glass-highlight-color);
    }

    .glass {
        padding: 16px 10px 0 16px;

        .boxes {
            display: flex;
            flex-direction: column;
            gap: 10px;

            &>div {
                display: flex;
                align-items: center;
                gap: 14px;

                label {
                    color: var(--glass-primary-color);
                }
            }

            .p-checkbox {

                .p-checkbox-box {
                    border: none !important;

                    &.p-highlight {
                        background: var(--accent);
                    }
                }
            }
        }
    }

    .p-button {
        width: 100%;
        margin-top: 30px;
        background: var(--accent);
        border: none;
        padding: .85rem 1.25rem;
        font-family: 'Rubik', sans-serif;

        span {
            color: var(--accent-text);
            font-weight: 500;
        }
    }
}
