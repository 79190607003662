.top {
    height: 56px;
    display: flex;
    align-items: center;

    .avatar {
        width: 74px;
        height: 74px;
        background: var(--darken);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -20px;

        .inner {
            width: 62px;
            height: 62px;
            border-radius: 50%;
            background: var(--lighten);
        }
    }

    .profile {
        font-weight: 500;
        font-style: italic;
        text-transform: uppercase;
        background: var(--accent);
        color: var(--accent-text);
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .balance {
            position: absolute;
            height: 32px;
            bottom: -23px;
            right: 20px;
            background: var(--darken);
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--darken-inner-text);
            padding-left: 24px;
            padding-right: 8px;

            &>div[role="button"] {
                position: absolute;
                left: -10px;
            }

            @media screen and (max-width: 370px) {
                font-size: 13px;
            }
        }
    }

    .coefficient {
      position: absolute;
      height: 30px;
      transform: skewX(-10deg);

      border-radius: 10px;
      top: 40px;
      right: 15px;
      font-size: 12px;
      z-index: 9;

      background: var(--secondary);
      color: var(--secondary-text);
      display: flex;
      align-items: center;
      justify-content: center;
      //padding-left: 8px;
      padding-left: 6px;
      padding-right: 8px;
      filter: drop-shadow(-5px 5px 5px #222);
      overflow: hidden;

      &>div[role="button"] {
        margin-bottom: 2px !important;
        margin-right: 1px !important;
        filter: drop-shadow(-3px 5px 7px red);
      }

      &>span {
        transition: all 0.3s ease-in-out;
        &.tooltip {
          margin-left: 1px;
          width: 65px;
          font-size: 9px;
          transform: skewX(-10deg);
        }
      }


      /*img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        margin-bottom: 2px;
        filter: drop-shadow(-3px 5px 7px red);
      }*/
    }

    .record {
      font-weight: 500;
      font-style: italic;
      text-transform: uppercase;
      background: var(--secondary);
      color: var(--secondary-text);
      height: 30px;
      width: max-content;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 10px;
      font-size: 11px;
      transform: skewX(-10deg);

      right: 18px;
      border-radius: 10px 10px 10px 0;
      /*right: 70px;
      border-radius: 10px 0 0 10px;*/

      .coefficient {
        position: absolute;
        height: 30px;

        border-radius: 0 0 10px 10px;
        top: 30px;
        right: 15px;
        font-size: 10px;
        /*border-radius: 0 10px 10px 0;
        top: 0;
        right: -50px;*/

        background: var(--darken);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--darken-inner-text);
        padding-left: 8px;
        padding-right: 8px;

        img {
          width: 10px;
          height: 10px;
          /*width: 12px;
          height: 12px;*/
          margin-right: 2px;
        }
      }
    }
}
