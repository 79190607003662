@media screen and (max-width: 390px) {
  .fantasy .mean > .history > .glass:not(.non-ideal) {

      > .date {
        width: 34px;
        font-size: 11px;
      }

      > .teams {
        width: 146px;
        > div {
          font-size: 11px;
        }
      }

      > .factor {
        width: 86px;

        .smooth {
          width: 34px;
          font-size: 12px;
        }

        b {
          font-size: 10px;
        }
      }

      &>.outcome {
        width: calc(100% - 262px);
        .glass {
          font-size: 13px;
          padding-right: 0;

          &:before {
            top: calc(50% - 7px);
            width: 14px;
            height: 14px;
          }

          b {
            top: calc(50% - 7px);
            width: 14px;
            height: 14px;

            i {
              font-size: 8px;
            }
          }
        }
      }
  }
}

@media screen and (max-width: 365px) {
  .fantasy .mean > .history > .glass:not(.non-ideal) {

    > .date {
      width: 30px;
      font-size: 10px;
    }

    > .teams {
      width: 142px;
      > div {
        font-size: 10px;
      }
    }

    > .factor {
      width: 82px;

      .smooth {
        width: 30px;
        font-size: 11px;
      }

      b {
        font-size: 9px;
      }
    }

    &>.outcome {
      width: calc(100% - 242px);
      .glass {
        font-size: 12px;

        &:before {
          top: calc(50% - 6px);
          width: 12px;
          height: 12px;
        }

        b {
          top: calc(50% - 6px);
          width: 12px;
          height: 12px;

          i {
            font-size: 6px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 340px) {
  .fantasy .mean > .history > .glass:not(.non-ideal) {

    > .date {
      width: 26px;
      font-size: 9.5px;
    }

    > .teams {
      width: 128px;
      > div {
        font-size: 9.5px;
      }
    }

    > .factor {
      width: 78px;

      .smooth {
        width: 26px;
        font-size: 10.5px;
      }

      b {
        font-size: 8.5px;
      }
    }

    &>.outcome {
      width: calc(100% - 224px);
      .glass {
        font-size: 11.5px;

        &:before {
          top: calc(50% - 6px);
          width: 12px;
          height: 12px;
        }

        b {
          top: calc(50% - 6px);
          width: 12px;
          height: 12px;

          i {
            font-size: 6px;
          }
        }
      }
    }
  }
}