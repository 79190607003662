.carousel {
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;

    .loader {
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        height: unset !important;
    }

    .splide {
        height: 100%;
        display: flex;
        flex-direction: column;

        .splide__track {
            height: calc(100% - 20px);
            overflow-y: visible;

            .splide__slide {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }

        .splide__pagination {
            bottom: -4px;
        }
    }
}
