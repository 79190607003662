.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: 999;
    transition: all 0.85s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &.shown {
        visibility: visible;
        opacity: 1;
    }
}
