.info-bar {
    height: 58px;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    grid-gap: 10px;

    .glass {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 60px;
        transition: all 0.3s ease-in-out;

        &.current {
            background: rgba(255, 255, 255, .3);

            span {
                opacity: 0;
                visibility: hidden;
            }

            img {
                width: 34px;
                transform: translateY(-6px);
            }
        }

        span {
            font-size: 9px;
            display: block;
            width: 100%;
            text-align: center;
            color: #fff;
            opacity: 0.9;
            margin-bottom: 4px;
            transition: all 0.3s ease-in-out;
        }

        img {
            width: 28px;
            transition: all 0.55s linear;
        }

        .estimated-bar {
            background: rgba(0, 0, 0, .25);
            height: 8px;
            width: calc(100% - 16px);
            margin-top: 10px;
            border-radius: 4px;
            position: relative;

            .estimated-value {
                height: 8px;
                position: absolute;
                border-radius: 4px;
                left: 0;
                background: var(--accent);
                opacity: .9;

                .estimated-indicator {
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: var(--accent);
                    color: var(--accent-text);
                    font-weight: 500;
                    font-size: 12px;
                    position: absolute;
                    right: -5px;
                    top: -5px;
                    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.45);
                }
            }
        }
    }
}
