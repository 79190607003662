@media screen and (max-width: 370px) {
  .fantasy .ratings {
    .types {
      padding: 0 6px 8px;

      .smooth {
        font-size: 12px;
        padding: 6px 6px;
      }
    }
  }
}